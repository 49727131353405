import { init as initializeSummoner } from '@bukalapak/summoner/dist-es5/browser';
import { buildInterface } from './build-api-interface';

function showFragmentError(fragment) {
  const errorTemplate = fragment.querySelector('template[data-error]');

  if (errorTemplate) {
    fragment.innerHTML = errorTemplate.innerHTML;
  }
}

function prepareEventListeners(fragments) {
  if (!fragments.length) {
    return;
  }

  Array.from(fragments).forEach(fragment => {
    fragment.addEventListener('summoner:fragmentError', () => {
      showFragmentError(fragment);
    });
  });
}

// async function run() {
//   const fragments = document.querySelectorAll('fragment-loader');
//   window.API = buildInterface(window.USER, window.APP);

//   const credential = await window.API.atm.fetchToken();
//   const accessToken = (credential || {}).access_token;
//   const baseUrl = window.APP.fragmentURL;

//   prepareEventListeners(fragments);
//   initializeSummoner({
//     accessToken,
//     baseUrl,
//     whitelist: window.APP.summonerWhitelist,
//   });
// }

/**
 * Temporarily avoid using `async-await` until the babel updated to support it.
 */
function run() {
  return Promise.resolve()
    .then(() => {
      window.API = buildInterface(window.USER, window.APP);
      return window.API.atm.fetchToken();
    })
    .then(credential => {
      const fragments = document.querySelectorAll('fragment-loader');
      const accessToken = (credential || {}).access_token;
      const baseUrl = window.APP.fragmentURL;

      prepareEventListeners(fragments);
      initializeSummoner({
        accessToken,
        baseUrl,
        whitelist: window.APP.summonerWhitelist,
      });
    });
}

run();
