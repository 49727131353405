import debounce from 'debounce-promise';
import { RequestManager, AccessTokenManager, wrapSecureClientInterface } from '@bukalapak/secure-client';

const POOL_LATENCY = 200; // debounce aggregation after 200ms

let aggregateClient = {};
let aggregationPool = {};

const performAggregation = debounce(() => {
  const aggregate = aggregationPool;
  aggregationPool = {};

  return aggregateClient
    .post('/aggregate', 'public user store', {
      data: {
        aggregate,
      },
    })
    .then(resp => {
      if (!resp) {
        throw new Error('Empty data after aggregation!');
      }

      if (Object.keys(resp.meta).length === 0) {
        throw new Error('Invalid request!');
      }

      const parsedResp = Object.keys(resp.meta).reduce((interfaces, key) => {
        interfaces[key] = {
          meta: resp.meta[key],
          ...(resp.data[key] ? { data: resp.data[key] } : {}),
          ...(resp.error[key] ? { errors: resp.error[key] } : {}),
        };

        return interfaces;
      }, {});

      return parsedResp;
    });
}, POOL_LATENCY);

function poolRequest(key, requestDetail) {
  aggregationPool[key] = requestDetail;
  return performAggregation().then(data => data[key]);
}

const REQUEST_ADAPTER = {
  get(path, scope = 'public', data = {}) {
    const { params } = data;
    const queryString = params
      ? Object.keys(params)
          .map(key => `${key}=${params[key]}`)
          .join('&')
      : '';

    return key =>
      poolRequest(key, {
        method: 'GET',
        path: queryString ? `${path}?${queryString}` : path,
        scope,
      });
  },
};

function wrapAggregate(requests) {
  return Object.keys(requests).reduce((aggregates, key) => {
    aggregates[key] = (...args) => requests[key].call(REQUEST_ADAPTER, ...args)(key);
    return aggregates;
  }, {});
}

export function buildInterface(USER, APP) {
  const atmConfig = {
    userId: USER.id,
    env: APP.env,
    authEndpoint: APP.apiAuthEndpoint,
    appId: 1,
    authToken: APP.apiAuthToken,
  };

  const atm = new AccessTokenManager(atmConfig);
  aggregateClient = new RequestManager(atm, APP.apiURL, 'essosAggregate');
  const requestManagerProxy = requestKey => new RequestManager(atm, APP.apiURL, requestKey);
  wrapSecureClientInterface.setup(atm, APP.apiURL);

  return {
    atm,
    config: atmConfig,
    wrap: wrapSecureClientInterface,
    wrapAggregate,
    requestManagerProxy,
  };
}

export default {
  buildInterface,
};
